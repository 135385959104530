.start {
  position: relative;
  overflow: hidden;
  video {
    display: block;
    width:100%;
    height: auto;
  }
  video[poster]{
    object-fit: cover;
  }
  &-inner {
    @extend .full;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-size: rem(20px);
    line-height: rem(30px);
    letter-spacing: rem(0.2px);
    text-align: center;
    @include media-breakpoint-down(xs) {
      position: relative;
      padding: 3rem 0;
    }
  }
  h3 {
    font-family: $fontTitle;
    font-size: rem(50px);
    line-height: rem(60px);
    letter-spacing: rem(2px);
    text-transform: uppercase;
    color:white;
    font-weight: normal;
    margin: rem(0 0 20px);
    @include media-breakpoint-down(xs) {
      font-size: rem(40px);
      line-height: rem(50px);
    }
  }
}