/* banner start */
.banner {
  position: relative;
  overflow: hidden;
  &--home {
    height: 100vh;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(lg) {
      height: auto;
      //padding: 13rem 0 6rem 0;
    }
    &:before{
      content: "";
      @extend .full;
      background: cl(black, 0.4);
      display: none;
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }
    .banner-text {
      @include media-breakpoint-down(lg) {
        padding: 0 5rem 0;
      }
      @include media-breakpoint-down(sm) {
        padding: 10rem 5rem 2rem;
      }
      @include media-breakpoint-down(xs) {
        padding: 25rem 1rem 2rem;
      }
    }
  }
  &--page {
    height: rem(535px);
    padding: rem(134px 0 0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(xs) {
      height: 20rem;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-family: $fontTitle;
      font-size: rem(50px);
      font-weight: normal;
      margin: 0;
      letter-spacing: rem(2px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
      }
    }
  }
  &-slider {
    user-select: unset;

    &-item {
      height: 100vh;
      @include media-breakpoint-down(lg) {
        //height: auto;
        //padding: 13rem 0 6rem 0;
      }
      @include media-breakpoint-down(sm) {
        height: auto;
      }
      @include media-breakpoint-down(xs) {
        //height: 100vh;
      }
    }
    &-image {
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
      @include media-breakpoint-up(lg) {
        background-attachment: fixed;
      }

      @include media-breakpoint-down(xs) {
        height: 15rem;
        margin-top: 8rem;
      }
    }
    &-prev,
    &-next {
      position: absolute;
      left:rem(60px);
      width:rem(40px);
      height: rem(40px);
      font-size: rem(16px);
      border:1px solid #fff;
      border-radius: 50%;
      color:#fff;
      z-index: 200;
      background: none;
      margin-top: rem(50px);
      @include media-breakpoint-up(sm) {
        top: 50%;
        transform: translateY(-50%);
      }
      @include media-breakpoint-down(lg) {
        left:1rem;
      }
      @include media-breakpoint-down(xs) {
        width:rem(30px);
        height: rem(30px);
        font-size: rem(15px);
        top:12rem;
      }
    }
    &-next {
      left:auto;
      right:rem(60px);
      @include media-breakpoint-down(lg) {
        right:1rem;
      }
    }
  }
  &-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      content: '';
      @extend .full;
      background: cl(black, 0.5);
      z-index: 2;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @include media-breakpoint-down(xs) {
        margin-left: 0;
        top: -20px;
        height: calc(100% + 20px);
      }
      @media #{$min-aspect169} {
        height: 400%;
        top: -150%;
      }
      @media #{$max-aspect169} {
        width: 400%;
        left: -150%;
      }
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    .ttl {
      font-family: $fontTitle;
      display: block;
      font-size: rem(50px);
      line-height: rem(60px);
      margin: rem(0 0 40px);
      color:cl(primary);
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        font-size: rem(40px);
        line-height: rem(50px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(34px);
        line-height: rem(44px);
        margin: rem(0 0 20px);
      }
    }
    ul {
      list-style: none;
      margin: rem(0 0 50px);
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 30px);
      }
      li {
        font-size: rem(25px);
        line-height: rem(30px);
        letter-spacing: rem(.2px);
        margin: rem(0 0 30px);
        padding: rem(0 0 0 46px);
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top:rem(5px);
          left:0;
          width:rem(24px);
          height: rem(18px);
          background: url(../img/svg/check.svg) no-repeat;
          background-size: contain;
        }
        @include media-breakpoint-down(lg) {
          font-size: rem(22px);
          line-height: rem(28px);
          margin: rem(0 0 20px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(20px);
          line-height: rem(26px);
          margin: rem(0 0 15px);
        }
      }
    }
    .sub-ttl {
      font-family: $fontTitle;
      display: block;
      font-size: rem(30px);
      line-height: rem(46px);
      letter-spacing: rem(.2px);
      margin: rem(0 0 25px);
      @include media-breakpoint-down(xs) {
        font-size: rem(26px);
        line-height: rem(36px);
        margin: rem(0 0 20px);
      }
    }
    .txt {
      display: block;
      font-size: rem(20px);
      line-height: rem(30px);
      letter-spacing: rem(.2px);
      margin: rem(0 0 30px);
      @include media-breakpoint-down(xs) {
        font-size: rem(18px);
        line-height: rem(28px);
      }
    }
    .note {
      font-size: rem(16px);
      line-height: rem(24px);
    }
    p {
      margin: 0;
    }
  }
  &-scroll {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      left:rem(60px);
      bottom: 0;
      padding: rem(0 0 45px);
      z-index: 201;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left:rem(7px);
        width:1px;
        height: rem(40px);
        background: white;
      }
      span {
        display: block;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        font-size: rem(15px);
        line-height: rem(18px);
        letter-spacing: rem(2px);
        @include media-breakpoint-down(xs) {
          font-size: rem(21px);
          font-weight: 600;
        }
      }
    }
  }
  &-line {
    background: cl(primary);
    padding: rem(55px 16px 45px);
    margin: rem(0 0 60px);
    @include media-breakpoint-down(xs) {
      padding:2rem;
      margin: 0 0 3rem;
    }
    &-logo {
      display: block;
      width: 100%;
      max-width:rem(880px);
      margin: 0 auto;
      height: auto;
      @include media-breakpoint-down(lg) {
        //width:26rem;
      }
      @include media-breakpoint-down(sm) {
        //width:18rem;
      }
    }
  }
}
/* banner end */

