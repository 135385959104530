.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	background: #29292d;
	box-shadow:rem(0 2px 6px cl(black, .25));
	&-top {
		height: rem(34px);
		background: cl(primary);
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color:black;
		font-size: rem(15px);
		padding: 0 2rem;
		font-family: $fontTitle;
		@include media-breakpoint-down(md) {
			font-size: rem(14px);
		}
		@include media-breakpoint-down(sm) {
			font-size: rem(12px);
		}
		@include media-breakpoint-down(xs) {
			padding: 0 1rem;
		}
		a {
			color:black;
		}
	}
	&-main {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: rem(0 60px);
		height: rem(100px);
		@include media-breakpoint-down(lg){
			padding: 0 2rem;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 1.5rem;
		}
	}
	&-logo {
		width:rem(260px);
		flex-shrink: 0;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
	}
	&-nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: rem(80px 0 0);
			background: #29292d;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		&-logo {
			display: none;
			width:rem(150px);
			height: auto;
			top:1rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}
		& > ul {
			display: flex;
			align-items: center;
			gap: rem(40px);
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				width:100%;
				height: 100%;
				overflow: auto;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 0;
			}
			& > li {
				position: relative;
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(white, 0.1));
					&:last-child {
						border-bottom:rem(1px solid cl(white, 0.1));
					}
				}
				& > a {
					display: block;
					font-size: rem(15px);
					line-height: rem(18px);
					color:cl(white);
					position: relative;
					text-transform: uppercase;
					letter-spacing: rem(1px);
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
					}
					&:hover {
						text-decoration: none;
						//color:cl(white);
						&:before {
							width:100%;
						}
					}
					&:before {
						content: "";
						@extend .pos-centerX;
						@include media-breakpoint-up(xl) {
							bottom: rem(-8px);
							width:0;
							height: rem(2px);
							background: cl(primary);
							transition: all .3s ease;
						}
					}
				}
				&.active {
					& > a {
						&:before {
							width:100%;
						}
						@include media-breakpoint-down(lg) {
							color:cl(primary)
						}
					}
				}
				&:hover {
					.drop {
						display: block;
					}
				}
				.drop {
					position: absolute;
					top:rem(17px);
					padding-top: rem(21px);
					left:0;
					width:rem(250px);
					display: none;
					@include media-breakpoint-down(lg) {
						display: block;
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
					}
					ul {
						margin: 0;
						background: cl(primary);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
						}
						li {
							a {
								display: block;
								font-size: rem(15px);
								line-height: rem(18px);
								color:cl(white);
								padding: rem(10px 10px 10px 20px);
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1.5rem 2rem;
									text-align: center;
									border-top:rem(1px solid cl(white, .1));
								}
								&:hover {
									text-decoration: none;
									background: cl(back);
								}
							}
							&.active {
								a {
									text-decoration: none;
									background: cl(primary);
									@include media-breakpoint-down(lg) {
										background: none;
										color:cl(primary)
									}
								}
							}
						}
					}
				}
			}
		}
		&-buttons {

			@include media-breakpoint-down(xs) {
				width:100%;
				padding: 1rem;
			}
		}
	}
	&-buttons {
		//position: relative;
		display: flex;
		justify-content: flex-end;
		gap:rem(35px);
		align-items: center;
		@include media-breakpoint-down(lg) {
			width:100%;
			margin: 0 2rem 0 0;
		}
		@include media-breakpoint-down(xs) {
			//padding: 1rem;
			//justify-content: space-between;
			//gap:0;
			//width:100%;
		}
		.btn {
			line-height: rem(46px);
			font-size: rem(15px);
			padding: rem(0 16px);
			@include media-breakpoint-down(xs) {
				//width:48%;
			}
		}
	}
	&-phone {
		color:white;
		font-size: rem(15px);
		white-space: nowrap;
		&:hover {
			text-decoration: none;
			color:cl(primary);
		}
		span {
			@include media-breakpoint-down(md) {
				width:rem(40px);
				height: rem(40px);
				border-radius: 50%;
				background: cl(primary);
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.goog-te-gadget-simple {
		background: transparent;
		border:0;
		.goog-te-menu-value {
			color:cl(text);
		}
	}
}
.translated-ltr {
	.header {
		margin-top: rem(39px);
		@include media-breakpoint-down(xs) {
			margin-top: rem(45px);
		}
		.header-nav {
			@include media-breakpoint-down(lg) {
				padding-top: 2.875rem;
			}
		}
		.header-nav-logo {
			top:4rem;
		}
		.close-nav-btn {
			top: 3.75rem;
		}
	}
}
body > .skiptranslate {
	//display: none!important;
}
/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height:rem(2px);
	background-color: cl(primary);
	content: "";
	transition: all 0.3s ease;
}
.c-hamburger {
	display: none;
	position: relative;
	margin: 0;
	padding: 0;
	height:rem(20px);
	width:rem(26px);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease  0.3s;
	&:focus {
		outline: none;
	}
	@include media-breakpoint-down(lg){
		display: block;
	}
	span {
		display: block;
		position: absolute;
		top:rem(9px);
		left: 0;
		right: 0;
		height:rem(2px);
		background: cl(primary);
		transition: all 0.3s ease;
	}
	span::before {
		@extend .btn-lines;
		top:rem(-7px);
	}
	span::after {
		@extend .btn-lines;
		bottom:rem(-7px);
	}
	&--htx {
		background-color: transparent;
		&.active {
			position: absolute;
			top:1.8rem;
			right:1rem;
			&:before {
				display: none;
			}
			span {
				background: none;
			}
			span::before {
				top: 0;
				transform:rotate(45deg);
				//background: cl(white);
			}
			span::after {
				bottom: 0;
				transform:rotate(-45deg);
				//background: cl(white);
			}
		}
	}
}
/*hamburger btn*/
