.footer {
  background: #202023;
  padding: rem(0 60px);
  color:cl(white);
  font-size: rem(16px);
  @include media-breakpoint-down(lg) {
    padding: 0 2rem;
  }
  @include media-breakpoint-down(xs) {
    padding: 0 15px;
  }
  &-top {
    padding: rem(45px 0);
  }
  &-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:rem(24px);
    @include media-breakpoint-down(sm) {
      justify-content: center;
      margin-top: 2rem;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(40px);
      color:cl(white);
      border:rem(1px solid cl(white));
      &:hover {
        text-decoration: none;
        color:cl(primary);
        border-color:cl(primary);
      }
    }
  }
  &-logo {
    display: block;
    width:rem(260px);
    max-width:100%;
    @include media-breakpoint-down(sm) {
      margin: 0 auto 2rem;
    }
    img {
      width:100%;
      height: auto;
    }
  }
  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap:rem(45px);
    text-transform: uppercase;
    letter-spacing: rem(1px);
    @include media-breakpoint-down(lg) {
      gap:rem(25px);
    }
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }
    a {
      color:cl(white);
      &:hover {
        text-decoration: none;
        color:cl(primary);
      }
    }
  }
  &-copy {
    display: block;
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  &-sep {
    background:cl(white, .16);
    height: 1px;
  }
  &-bottom {
    padding: rem(22px 0 25px);
    color:#8c8c8d;
    a {
      color:#8c8c8d;
    }
  }
  &-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:rem(45px);
    @include media-breakpoint-down(xs) {
      justify-content: center;
      margin-bottom: 1.5rem;
      gap:rem(25px);
    }
  }
}

