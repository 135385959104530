.cash {
  font-size: rem(20px);
  line-height: rem(30px);
  color:cl(text);
  letter-spacing: rem(.2px);
  padding: rem(125px 0 70px);
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding: 5rem 0 3.5rem;
  }
  .cash-item {
    padding: rem(45px 260px 120px 60px);
    gap:rem(75px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap:rem(60px);
      padding: rem(70px 50px 0);
    }
    @include media-breakpoint-down(xs) {
      padding: 0 1rem;
      gap:3rem;
    }
    &-logo {
      position: absolute;
      right:rem(60px);
      top:0;
      width:rem(380px);
      height: auto;
    }
    &-images {
      width:rem(700px);
      //height: rem(311px);
      background: cl(primary);
      position: relative;
      box-shadow:rem(0 14px 43px cl(black, .3));
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-shrink: 0;
      padding: rem(0 50px 42px);
      z-index: 2;
      @include media-breakpoint-down(xs) {
        width:100%;
        height: auto;
        padding: rem(20px);
      }
      img {
        margin-top: rem(-42px);
        width:rem(173px);
        height: auto;
        box-shadow:rem(0 14px 43px cl(black, .3));
        @include media-breakpoint-down(xs) {
          width:30%;
        }
        &:nth-child(1) {
          top:rem(-26px);
          left:rem(-48px);
          @include media-breakpoint-down(xs) {
            left:-2rem;
          }
        }
        &:nth-child(2) {
          margin-top: rem(37px);
          margin-bottom: rem(-70px);
          @include media-breakpoint-down(xs) {
            margin-top: rem(30px);
            margin-bottom: rem(-30px);
          }
        }
      }
    }
    &-text {
      width:100%;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        width:100%;
      }
    }
    h2 {
      text-transform: uppercase;
      color:white;
      font-size: rem(40px);
      line-height: rem(50px);
      margin: rem(0 0 30px);
    }
  }
}