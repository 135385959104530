@font-face {
  font-family: 'FuturaBT-Medium';
  src: url('../fonts/FuturaBT-Medium.eot');
  src: url('../fonts/FuturaBT-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaBT-Medium.woff2') format('woff2'),
  url('../fonts/FuturaBT-Medium.woff') format('woff'),
  url('../fonts/FuturaBT-Medium.ttf') format('truetype'),
  url('../fonts/FuturaBT-Medium.svg#FuturaBT-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FuturaBT-Book';
  src: url('../fonts/FuturaBT-Book.eot');
  src: url('../fonts/FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaBT-Book.woff2') format('woff2'),
  url('../fonts/FuturaBT-Book.woff') format('woff'),
  url('../fonts/FuturaBT-Book.ttf') format('truetype'),
  url('../fonts/FuturaBT-Book.svg#FuturaBT-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
//@font-face {
//  font-family: 'Futura-Black-Bold';
//  src: url('../fonts/Futura-Black-Bold.eot');
//  src: url('../fonts/Futura-Black-Bold.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/Futura-Black-Bold.woff2') format('woff2'),
//  url('../fonts/Futura-Black-Bold.woff') format('woff'),
//  url('../fonts/Futura-Black-Bold.ttf') format('truetype'),
//  url('../fonts/Futura-Black-Bold.svg#Futura-Black-Bold') format('svg');
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}
@font-face {
  font-family: 'FuturaBT-Heavy';
  src: url('../fonts/FuturaBT-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaBT-Heavy.woff') format('woff'),
  url('../fonts/FuturaBT-Heavy.ttf')  format('truetype'),
  url('../fonts/FuturaBT-Heavy.svg#FuturaBT-Heavy') format('svg');
  font-weight: normal;
  font-style: normal;
}
//@font-face {
//  font-family: 'FuturaBT-ExtraBlack';
//  src: url('../fonts/FuturaBT-ExtraBlack.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/FuturaBT-ExtraBlack.woff') format('woff'),
//  url('../fonts/FuturaBT-ExtraBlack.ttf')  format('truetype'),
//  url('../fonts/FuturaBT-ExtraBlack.svg#FuturaBT-ExtraBlack') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}