.features {
  padding: rem(73px 0 60px);
  @include media-breakpoint-down(xs) {
    padding: 2rem 0;
  }
  h2 {
    font-family: $fontTitle;
    font-size: rem(40px);
    line-height: rem(50px);
    letter-spacing: rem(1px);
    text-transform: uppercase;
    color:white;
    font-weight: normal;
    margin: rem(0 0 30px);
    @include media-breakpoint-down(xs) {
      font-size: rem(34px);
      line-height: rem(44px);
      margin: rem(0 0 20px);
    }
  }
  &-info {
    font-size: rem(20px);
    line-height: rem(30px);
    letter-spacing: rem(.2px);
    color:cl(text);
    overflow: hidden;
    ul {
      list-style: none;
      & > li {
        position: relative;
        padding: rem(0 0 0 50px);
        margin: rem(0 0 35px);
        &:before {
          content: "";
          position: absolute;
          left:0;
          top:rem(2px);
          width:rem(25px);
          height: rem(25px);
          background: url(../img/list-mark.png) no-repeat;
          background-size: contain;
        }
        h3 {
          color:white;
          font-size: rem(18px);
          line-height: rem(30px);
          font-weight: normal;
          margin: 0;
        }
        ul {
          list-style:  none;
          font-size: rem(18px);
          & + h3 {
            margin-top: rem(30px);
          }
          li {
            padding: rem(0 0 0 18px);
            margin: 0;
            position: relative;
            background: none;
            &:before {
              content: "";
              position: absolute;
              left:0;
              top:rem(13px);
              width:rem(5px);
              height: rem(5px);
              border-radius: 50%;
              background: cl(text);
            }
          }
        }
      }
    }
  }
  &-text{
    font-size: rem(20px);
    line-height: rem(30px);
    letter-spacing: rem(.2px);
    color: cl(text);
    padding: rem(50px 0 40px);
    position: relative;
    overflow: hidden;
    &-logo {
      position: absolute;
      top:0;
      left:rem(60px);
      width:rem(290px);
      height: auto;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h2 {
      margin: rem(0 0 40px);
    }
    h3 {
      font-family: $fontTitle;
      font-size: rem(24px);
      line-height: rem(34px);
      letter-spacing: rem(1px);
      text-transform: uppercase;
      color:white;
      font-weight: normal;
      margin: rem(0 0 40px);
    }
    h4 {
      font-family: $fontTitle;
      font-size: rem(20px);
      line-height: rem(26px);
      letter-spacing: rem(2px);
      text-transform: uppercase;
      color:white;
      font-weight: 600;
      margin: rem(0 0 15px);
    }
    ul {
      list-style:  none;
      li {
        padding: rem(0 0 0 50px);
        margin: rem(0 0 25px);
        position: relative;
        //color:white;
        &:before {
          content: "";
          position: absolute;
          left:0;
          top:rem(1px);
          width:rem(25px);
          height: rem(25px);
          background: url(../img/list-mark.png) no-repeat;
          background-size: contain;
        }
      }
    }
  }
  &-value {
    @include media-breakpoint-down(xs) {
      padding: 1rem 0 0;
    }
    h2 {
      text-align: center;
      margin: rem(0 0 40px);
    }
    &-logo {
      @include media-breakpoint-up(xl) {
        position: absolute;
        top:50%;
        width:rem(390px);
        height: auto;
        margin-top: rem(-40px);
        &--l {
          left:rem(-160px);
          transform: rotate(-90deg);
        }
        &--r {
          right:rem(-160px);
          transform: rotate(90deg);
        }
      }
    }
    &-inner {
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: rem(40px);
      margin: rem(0 0 30px);
      @include media-breakpoint-up(xl) {
        padding: rem(0 100px);
      }
      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media-breakpoint-down(xs) {
        grid-gap: rem(20px);
      }
    }
    &-item {
      padding: rem(40px 25px 25px);
      border:1px solid cl(border);
      box-shadow:rem(0 0 35px cl(black, .5));
      text-align: center;
      font-size: rem(18px);
      line-height: rem(28px);
      letter-spacing: rem(.2px);
      color:cl(text);
      background: cl(back);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap:rem(30px);
      @include media-breakpoint-up(lg) {
        min-height: rem(300px);
      }
      img {
        height: rem(80px);
        //margin: rem(0 auto 30px);
        width:auto;
      }
      h3 {
        font-family: $fontTitle;
        font-size: rem(20px);
        line-height: rem(30px);
        letter-spacing: rem(1px);
        text-transform: uppercase;
        color:white;
        font-weight: normal;
        margin: 0;
      }
    }
  }
}