$font: 'FuturaBT-Book', sans-serif;
$fontTitle: 'FuturaBT-Medium', sans-serif;
//$fontBold: 'Futura-Black-Bold', sans-serif;
$fontHeavy: 'FuturaBT-Heavy', sans-serif;
//$fontExtraBlack: 'Futura-ExtraBlack', sans-serif;

$min-aspect169 : "(min-aspect-ratio: 16/9)";
$max-aspect169 : "(max-aspect-ratio: 16/9)";

$colors_theme : (
        "primary"   : "primary",
        "secondary" : "secondary",
        "back" : "back",
        "border" : "border",
        "text" : "text",
        "gray" : "gray",
        "black" : "black",
        "white" : "white"
);

$colors : (
        'primary'  : #c5a44a,
        'secondary'  : #ed554e,
        'back'  : #1a1a1c,
        'border'  : #313133,
        'text'  : #8c8c8d,
        'gray'  : #aaaaaa,
        'white' : #FFFFFF,
        'black' : #000000,
);

.color {
  @each $status, $color in $colors {
    &--#{$status} {
      color: $color!important;
    }
  }
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@function cl($name, $opacity: false) {
  @if $opacity {
    @return rgba(var(--color-#{unquote($name)}--rgb), $opacity);
  } @else {
    @return var(--color-#{unquote($name)});
  }
}

:root{
  @if $colors {
    @if $colors_theme {
      @each $key, $value in $colors_theme {
        --color-#{$key} : var(--color-#{$value});
        --color-#{$key}--rgb : var(--color-#{$value}--rgb);
      }
    }

    @each $key, $value in $colors {
      --color-#{$key} : #{$value};
      --color-#{$key}--rgb : #{HexToRGB($value)};
    }
  }
}