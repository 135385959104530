.contact {
  &-section {
    padding: rem(45px 60px 15px);
    border:1px solid cl(border);
    box-shadow:rem(0 0 35px cl(black, .5));
    margin: rem(160px 0 45px);
    font-size: rem(20px);
    color:cl(text);
    position: relative;
    @include media-breakpoint-down(xs) {
      margin-top: 5rem;
      padding: 2.5rem 1.5rem .5rem;
    }
    h2 {
      font-family: $fontTitle;
      font-size: rem(40px);
      font-weight: normal;
      margin: rem(0 0 10px);
      color:white;
      letter-spacing: rem(2px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        font-size: rem(34px);
      }
    }
    &-logo {
      position: absolute;
      left:2%;
      bottom: calc(100% - 1.5rem);
      width:96%;
      height: auto;
      @include media-breakpoint-down(xs) {
        bottom: calc(100% - 1rem);
      }
    }
  }
  &-call {
    text-align: center;
    margin: rem(0 0 45px);
    font-size: rem(24px);
    font-family: $fontTitle;
    letter-spacing: rem(2px);
    text-transform: uppercase;
    a {
      color:white;
      &:hover {
        text-decoration: none;
        color:cl(primary);
      }
    }
  }
}