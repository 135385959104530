.static {
  font-size: rem(20px);
  line-height: rem(30px);
  color:cl(text);
  letter-spacing: rem(.2px);
  padding: rem(285px 0 60px);
  @include media-breakpoint-down(xs) {
    padding-top: 12rem;
  }
  &-title {
    position: relative;
    margin: rem(0 0 50px);
    padding: rem(0 0 15px);
    h1 {
      font-size: rem(50px);
      line-height: rem(60px);
      margin: 0;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      font-family: $fontTitle;
      color:white;
      letter-spacing: rem(1px);
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
        line-height: rem(50px);
      }
    }
    &-logo {
      position: absolute;
      left:7%;
      bottom: 0;
      width:86%;
      height: auto;
      @include media-breakpoint-down(xs) {
        bottom: -1rem;
      }
    }
  }
  &-text {
    p {
      margin: rem(0 0 25px);
    }
    h2 {
      font-size: rem(30px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h3 {
      font-size: rem(24px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h4 {
      font-size: rem(20px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h5 {
      font-size: rem(18px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h6 {
      font-size: rem(16px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    ol, ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
      ul {
        margin-top: rem(10px);
        padding: rem(0 0 0 25px);
      }
    }
    img {
      display: block;
      margin: 0 auto 2rem;
      max-width:90%;
      height: auto;
    }
  }
}