.home {
  h2 {
    font-family: $fontTitle;
    font-size: rem(40px);
    line-height: rem(50px);
    letter-spacing: rem(1px);
    text-transform: uppercase;
    color:white;
    font-weight: normal;
    margin: rem(0 0 30px);
    @include media-breakpoint-down(xs) {
      font-size: rem(34px);
      line-height: rem(44px);
      margin: rem(0 0 20px);
    }
  }
  &-hardware {
    overflow: hidden;
    padding: rem(40px 0);
    color:cl(text);
    font-size: rem(22px);
    line-height: rem(32px);
    @include media-breakpoint-down(xs) {
      padding: 3rem 0;
    }
    &-image {
      @include media-breakpoint-up(xl) {
        margin: 0 auto;
        max-width:rem(530px);
      }
      @include media-breakpoint-down(md) {
        margin: 0 0 1rem 0;
      }
      img {
        max-width:100%;
        height: auto;
        display: block;
      }
    }
    //ul {
    //  margin: rem(0 0 30px);
    //  li {
    //    list-style: none;
    //    position: relative;
    //    padding: rem(0 0 5px 35px);
    //    &:before {
    //      content: "";
    //      position: absolute;
    //      top:rem(12px);
    //      left:0;
    //      width:rem(9px);
    //      height: rem(9px);
    //      background: cl(primary);
    //      border-radius: 50%;
    //    }
    //  }
    //}
    &-slider {
      //width:rem(532px);
      margin: 0 auto;
      position: relative;
      @include media-breakpoint-down(xs) {
        width:100%;
      }
      &-item {
        width:100%;
        height: rem(540px);
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
          margin-top: 4rem;
        }
        @include media-breakpoint-down(xs) {
          height: 28rem;
        }
      }
      .slick-dots {
        position: absolute;
        //right:calc(100% + 1.5rem);
        right:90%;
        top:40%;
        list-style: none;
        text-align: right;
        margin: 0;
        z-index: 200;
        @include media-breakpoint-down(lg) {
          //position: relative;
          right:50%;
          top:0;
        }
        @include media-breakpoint-down(md) {
          right:auto;
          text-align: center;
          width:100%;
        }
        li {
          margin: rem(0 0 5px);
          @include media-breakpoint-down(xs) {
            display: flex;
            justify-content: center;
          }
          button {
            background: none;
            border: 0;
            color:white;
            font-size: rem(20px);
            line-height: rem(26px);
            padding: rem(0 28px 0 0);
            position: relative;
            white-space: nowrap;
            @include media-breakpoint-down(xs) {
              font-size: rem(20px);
              line-height: rem(26px);
            }
            &:after {
              content: "";
              position: absolute;
              right:0;
              top:rem(10px);
              width:rem(11px);
              height: rem(11px);
              border-radius: 50%;
              background: #505156;
            }
            &:focus {
              outline: none;
            }
          }
          &.slick-active {
            button {
              color: cl(primary);
              &:after {
                background: cl(primary);
              }
            }
          }
        }
      }
    }
  }
  &-offer {
    padding: rem(95px 60px 260px 250px);
    margin: rem(0 0 90px);
    overflow: hidden;
    position: relative;
    @include media-breakpoint-down(lg) {
      padding: 5rem 2rem 13rem;
      margin: 0;
    }
    @include media-breakpoint-down(xs) {
      padding: 2rem 15px 6rem;
      margin: 0 0 1.5rem;
    }
    &--features {
      padding: rem(0 250px 70px 60px);
      margin: 0;
      @include media-breakpoint-down(lg) {
        padding: 2rem 2rem 7rem;
      }
      @include media-breakpoint-down(xs) {
        padding: 2rem 15px 5rem;
      }
    }
    &--reverse {
      padding: rem(95px 250px 260px 60px);
      @include media-breakpoint-down(lg) {
        padding: 5rem 2rem 13rem;
        margin: 0;
      }
      @include media-breakpoint-down(xs) {
        padding: 2rem 15px 6rem;
      }
    }
    &--margin {
      margin-bottom: rem(-80px);
    }
    &-text {
      font-size: rem(22px);
      line-height: rem(32px);
      letter-spacing: rem(0.2px);
      color:cl(text);
      position: relative;
      z-index: 2;
      &-title {
        text-transform: uppercase;
        color:white;
        font-size: rem(40px);
        line-height: rem(30px);
        margin: rem(0 0 30px);
      }
      ul {
        margin: rem(0 0 30px);
        li {
          list-style: none;
          position: relative;
          padding: rem(0 0 5px 35px);
          &:before {
            content: "";
            position: absolute;
            top:rem(12px);
            left:0;
            width:rem(9px);
            height: rem(9px);
            background: cl(primary);
            border-radius: 50%;
          }
        }
      }
    }
    &-video,
    &-image {
      position: relative;
      z-index: 2;
      box-shadow:rem(0 14px 43px cl(black, .3));
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
      video,
      img {
        display: block;
        width:100%;
        height: auto;
      }
      video[poster]{
        object-fit: cover;
      }
    }
    &-logo {
      position: absolute;
      left:2%;
      bottom: 0;
      width:96%;
      height: auto;
    }
  }
  &-features {
    font-size: rem(20px);
    line-height: rem(30px);
    letter-spacing: rem(0.2px);
    padding: rem(95px 0);
    position: relative;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(xs) {
      padding: 4rem 0;
    }
    &:before {
      content: '';
      @extend .full;
      background: cl(black, 0.5);
      z-index: 2;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h2 {
      font-size: rem(30px);
      line-height: rem(40px);
      font-family: $fontHeavy;
      font-weight: normal;
      text-transform: none;
      margin: rem(0 0 15px);
    }
    &-grid {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: rem(23px 27px);
      margin: rem(50px 0);
      @include media-breakpoint-down(xs) {
        gap: rem(16px);
        margin: rem(20px 0 40px);
      }
    }
    &-item {
      width:rem(230px);
      height: rem(160px);
      box-shadow:rem(0 0 35px cl(black, .5));
      border:1px solid cl(border);
      background: cl(back, .8);
      padding: rem(25px 10px 5px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-size: rem(18px);
      line-height: rem(25px);
      letter-spacing: rem(.2px);
      color:white;
      text-align: center;
      @include media-breakpoint-down(xs) {
        width:rem(200px);
      }
      img {
        height: rem(55px);
        width:auto;
        margin: rem(0 0 15px);
      }
    }
    &-all {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-transform: uppercase;
      font-size: rem(18px);
      line-height: rem(20px);
      letter-spacing: rem(1px);
      font-family: $fontTitle;
      span {
        position: relative;
        padding: rem(0 15px);
        &:before,
        &:after {
          content: '';
          @extend .pos-centerY;
          background: cl(primary);
          height: 1px;
          width:rem(1000px);
        }
        &:before {
          right:100%;
        }
        &:after {
          left:100%;
        }
      }
      a {
        color:white;
        &:hover {
          text-decoration: none;
          color:cl(primary);
        }
      }
    }
  }
  &-discount {
    padding: rem(90px 60px 120px 250px);
    overflow: hidden;
    position: relative;
    @include media-breakpoint-down(lg) {
      padding: 4rem 2rem;
    }
    &-text {
      font-size: rem(20px);
      line-height: rem(30px);
      letter-spacing: rem(0.2px);
      color:#8c8c8d;
    }
    &-hold {
      position: relative;
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
      @include media-breakpoint-down(sm) {
        margin-left: 3rem;
      }
      @include media-breakpoint-down(xs) {
        margin: 0 0 5rem;
      }
    }
    &-image {
      box-shadow:rem(0 14px 43px cl(black, .3));
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      z-index: 2;
      //height: rem(550px);
      padding-top: 71.5%;
    }
    &-video {
      position: relative;
      z-index: 2;
      box-shadow:rem(0 14px 43px cl(black, .3));
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
      video {
        display: block;
        width:100%;
        height: auto;
      }
      video[poster]{
        object-fit: cover;
      }
    }
    &-ttl {
      position: absolute;
      right:100%;
      top:5%;
      height: 90%;
      width:rem(87px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: rem(24px);
      line-height: rem(26px);
      letter-spacing: rem(.4px);
      background: cl(secondary);
      text-align: center;
      @include media-breakpoint-up(sm) {
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
      @include media-breakpoint-down(sm) {
        width:rem(60px);
      }
      @include media-breakpoint-down(xs) {
        left:5%;
        top:100%;
        width: 90%;
        height: rem(60px);
        font-size: rem(22px);
      }
      span {
        //transform:scaleX(-1);;
      }
    }
  }
  &-testimonials {
    padding: rem(35px 0 100px);
    position: relative;
    @include media-breakpoint-down(xs) {
      padding: 3rem 0 7rem;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h2 {
      text-align: right;
      margin: rem(0 33px -23px 0);
      position: relative;
      z-index: 200;
      @include media-breakpoint-down(xs) {
        text-align: center;
        margin: 0 0 2rem;
      }
      span {
        display: inline-block;
        vertical-align: top;
        padding: rem(0 16px);
        background: var(--color-back);
      }
    }
    &-holder {
      border:1px solid #313133;
      position: relative;
      @include media-breakpoint-up(sm) {
        background: url(../img/testim-logo.png) no-repeat 1rem 50%;
        background-size: rem(102px auto);
        padding: rem(60px 45px 25px 125px);
      }
      @include media-breakpoint-down(xs) {
        padding: rem(25px 25px 25px 25px);
      }
    }
    &-quote {
      position: absolute;
      bottom: rem(-10px);
      right:rem(-10px);
      padding: rem(9px 0 0 5px);
      background: var(--color-back);
      z-index: 200;
      img {
        width:rem(60px);
        height: auto;
      }
    }
    &-logo {
      position: absolute;
      top:5%;
      left:rem(16px);
      width:auto;
      height: 90%;
    }
    &-slider {

    }
    &-item {
      //padding: rem(0 22px);
    }
    video {
      display: block;
      width:100%;
      height: auto;
    }
    video[poster]{
      object-fit: cover;
    }
    &-iframe {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &-name {
      margin: rem(25px 0 5px);
      font-size: rem(18px);
      line-height: rem(28px);
      font-family: $fontTitle;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    &-position {
      font-size: rem(16px);
    }
    &-prev,
    &-next {
      position: absolute;
      width:rem(40px);
      height: rem(40px);
      border-radius: 50%;
      border: 1px solid cl(primary);
      color:cl(primary);
      background: none;
      &:focus {
        outline: none;
        background: cl(back);
      }
      @include media-breakpoint-up(lg) {
        left:rem(-203px);
        top: 50%;
        transform: translateY(-50%);
      }
      @include media-breakpoint-down(md) {
        bottom: -6rem;
        left:6rem;
      }
      @include media-breakpoint-down(xs) {
        left:0;
      }
    }
    &-next {
      left:auto;
      @include media-breakpoint-up(lg) {
        right:rem(-121px);
      }
      @include media-breakpoint-down(md) {
        right:-1.5625rem;
      }
      @include media-breakpoint-down(xs) {
        right:0;
      }
    }
  }
  &-news {
    padding: rem(70px 25px 10px 385px);
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      padding-left: 14rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 3rem 1rem 3rem;
    }
    h2 {
      position: absolute;
      left:rem(60px);
      top:rem(200px);
      z-index: 2;
      @include media-breakpoint-down(xs) {
        position: static;
        text-align: center;
        margin: rem(0 0 10px);
      }
    }
    &-logo {
      position: absolute;
      top:rem(100px);
      left:rem(38px);
      width:rem(330px);
      height: auto;
      @include media-breakpoint-down(xs) {
        top: auto;
        bottom: 1rem;
        left:1rem;
        width:10rem;
      }
    }
    &-prev,
    &-next {
      flex-shrink: 0;
      width:rem(40px);
      height: rem(40px);
      border-radius: 50%;
      border: 1px solid cl(primary);
      color:cl(primary);
      background: none;
      &:focus {
        outline: none;
        background: cl(back);
      }
    }
  }
  &-customers {
    padding: rem(75px 0);
    h2 {
      text-align: center;
    }
    .customers-slider {
      padding: rem(0 50px);
      &-item {
        display: block;
        margin: rem(0 5px);
        height: rem(100px);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        filter: grayscale(1);
        opacity: .5;
        transition: all.3s ease;
        &:hover {
          filter: grayscale(0);
          opacity: 1;
        }
      }
      &-prev,
      &-next {
        @extend .pos-centerY;
        left:0;
        width:rem(40px);
        height: rem(40px);
        border-radius: 50%;
        border: 1px solid cl(primary);
        color:cl(primary);
        background: none;
        &:focus {
          outline: none;
          background: cl(back);
        }
      }
      &-next {
        left: auto;
        right:0;
      }
    }
  }
}