.news {
  padding: rem(50px 0 70px);
  overflow: hidden;
  &-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem(35px 0);
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-filter {
    margin: rem(0 0 40px);
    h4 {
      text-align: center;
      color:white;
      font-size: rem(20px);
      text-transform: uppercase;
      font-weight: normal;
      font-family: $fontTitle;
      letter-spacing: rem(1px);
      margin: rem(0 0 25px);
    }
    &-inner {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap:rem(22px);
      flex-wrap: wrap;
      font-size: rem(16px);
      @include media-breakpoint-down(xs) {
        gap:rem(12px);
      }
      a {
        color:cl(text);
        border-radius: rem(2px);
        border: 1px solid cl(white, .2);
        line-height: rem(40px);
        padding: rem(0 30px);
        transition: all .3s ease;
        &:hover {
          color:white;
          border-color:cl(white, .5);
          text-decoration: none;
        }
        &.current {
          color:white;
          border-color:cl(primary);
        }
        @include media-breakpoint-down(xs) {
          line-height: rem(30px);
          padding: rem(0 15px);
        }
      }
    }
  }
  &-sort{
    display: flex;
    justify-content: space-between;
    gap:rem(20px);
    flex-wrap: wrap;
    margin: rem(0 0 45px);
    &-item {
      width:25%;
      @include media-breakpoint-down(md) {
        width:calc(50% - 0.625rem);
      }
      .news-sort-select {
        display: block;
        width:100%;
        font-family: $font;
        height: rem(35px);
        border-radius: 0;
        border:0;
        border-bottom:rem(1px solid cl(white, .2));
        padding:rem(0 40px 0 0);
        font-size:rem(18px);
        color:white;
        background-color: transparent;
        background-image: url(../img/select-arrow.png);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: rem(16px auto);
        padding-right: rem(25px);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
          outline: none;
          box-shadow:none;
          border-bottom-color:cl(white);
        }
      }
    }
  }
  &-search {
    position: relative;
    width:50%;
    @include media-breakpoint-down(md) {
      width:calc(50% - 0.625rem);
    }
    &-txt {
      display: block;
      width:100%;
      font-family: $font;
      height: rem(35px);
      border-radius: 0;
      border:0;
      border-bottom:rem(1px solid cl(white, .2));
      background: none;
      padding:rem(0 40px 0 0);
      font-size:rem(18px);
      color:white;
      @include placeholder(cl(text));
      &:focus {
        outline: none;
        box-shadow:none;
        background: none;
        border-bottom-color:cl(white);
      }
    }
    &-btn {
      background: none;
      border:0;
      position: absolute;
      right:0;
      top:0;
      height: rem(35px);
      width: rem(35px);
      color:cl(primary);
      font-size:rem(18px);
    }
  }
  &-article {
    font-size: rem(20px);
    line-height: rem(30px);
    letter-spacing: rem(.2px);
    color:cl(text);
    @include media-breakpoint-up(xl) {
      padding-right: rem(25px);
    }
    @include media-breakpoint-down(md) {
      padding-bottom: rem(40px);
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-share {
      display: flex;
      gap:rem(28px);
      color:white;
      font-size: rem(16px);
      a {
        color:white;
        &:hover {
          text-decoration: none;
          color: cl(primary);
        }
      }
      span {
        position: relative;
        font-size: rem(14px);
        &:before {
          content:"";
          position: absolute;
          left:rem(-47px);
          width:rem(35px);
          height: 1px;
          top:50%;
          transform: translateY(-50%);
          background: cl(primary);
        }
      }
    }
    p {
      margin: rem(0 0 30px);
    }
    h2 {
      font-size: rem(30px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h3 {
      font-size: rem(24px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h4 {
      font-size: rem(20px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h5 {
      font-size: rem(18px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    h6 {
      font-size: rem(16px);
      margin: rem(0 0 20px);
      font-weight: normal;
      font-family: $fontTitle;
      color:white;
    }
    ol, ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
      ul {
        margin-top: rem(10px);
        padding: rem(0 0 0 25px);
      }
    }
    img {
      display: block;
      margin: 0 auto 2rem;
      max-width:100%;
      height: auto;
    }
  }
  &-sidebar {
    hr {
      border-color:cl(white, .1);
      margin-top: rem(20px);
      margin-bottom: rem(20px);
    }
    h4 {
      color:white;
      font-size: rem(20px);
      text-transform: uppercase;
      font-weight: normal;
      font-family: $fontTitle;
      letter-spacing: rem(1px);
      margin: rem(0 0 25px);
    }
    &-articles {
      list-style: none;
      margin: 0;
      li {
        display: flex;
        align-items: flex-start;
        gap:rem(20px);
        margin: rem(0 0 25px);
        h5 {
          font-weight: 700;
          color:cl(white);
          font-size: rem(18px);
          line-height: rem(26px);
          margin: rem(0 0 8px);
          height: rem(78px);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          a{
            color:cl(white);
            &:hover {
              text-decoration: none;
              color:cl(primary);
            }
          }
        }
      }
      &-img {
        flex-shrink: 0;
        width:rem(126px);
        padding-top: 23%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
      }
    }
    &-more {
      display: inline-block;
      vertical-align: top;
      position: relative;
      font-size: rem(14px);
      color:cl(white);
      text-transform: uppercase;
      &:before {
        content:"";
        position: absolute;
        right:rem(-47px);
        width:rem(35px);
        height: 1px;
        top:50%;
        transform: translateY(-50%);
        background: cl(primary);
      }
      &:hover {
        text-decoration: none;
        color:cl(primary);
      }
    }
    &-categories {
      list-style: none;
      margin: 0;
      li {
        margin: rem(0 0 10px);
        padding: rem(0 0 0 28px);
        font-size: rem(18px);
        line-height: rem(22px);
        position: relative;
        &:after {
          content: '\f104';
          position: absolute;
          top:0;
          left: 0;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-size: rem(15px);
          color: cl(primary);
        }
        a {
          color:cl(white);
          &:hover {
            text-decoration: none;
            color:cl(primary);
          }
        }
      }
    }
    &-social {
       display: flex;
       gap:rem(25px);
       color:white;
       font-size: rem(16px);
       a {
         display: flex;
         justify-content: center;
         align-items: center;
         width:rem(40px);
         height:rem(40px);
         border-radius: 50%;
         border:1px solid white;
         background: cl(white,.05);
         color:white;
         &:hover {
           text-decoration: none;
           background: cl(white);
           color: cl(back);
         }
       }
       span {
         position: relative;
         font-size: rem(14px);
         &:before {
           content:"";
           position: absolute;
           left:rem(-47px);
           width:rem(35px);
           height: 1px;
           top:50%;
           transform: translateY(-50%);
           background: cl(primary);
         }
       }
     }
  }
  &-slider {
    padding: rem(0 35px);
    position: relative;
    z-index: 3;
    .item {
      padding: rem(35px 0);
    }
  }
  &-panel {
    position: absolute;
    z-index: 2;
    left:rem(60px);
    bottom: rem(140px);
    display: flex;
    gap:rem(25px);
    @include media-breakpoint-down(xs) {
      position: static;
      justify-content: center;
    }
  }
  &-prev,
  &-next {
    flex-shrink: 0;
    width:rem(40px);
    height: rem(40px);
    border-radius: 50%;
    border: 1px solid cl(primary);
    color:cl(primary);
    background: none;
    &:focus {
      outline: none;
      background: cl(back);
    }
  }
  &-item {
    border:1px solid cl(border);
    position: relative;
    overflow: hidden;
    transition: all.3s ease;
    background: cl(back);
    &:hover {
      border-color:cl(primary);
      box-shadow:rem(0 0 35px cl(black, .5));
      @include media-breakpoint-up(xl) {
        .news-item-image {
          opacity: .37;
        }
        .news-item-text {
          opacity: 0;
        }
        .news-item-more {
          transform: scale(1);
        }
      }
    }
    &-image {
      display: block;
      height: rem(200px);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all.3s ease;
    }
    &-date{
      display: block;
      color:#8c8c8d;
      font-size: rem(16px);
      line-height: rem(18px);
      margin: rem(0 0 15px);
    }
    &-inner {
      padding: rem(25px 30px 45px);
      @include media-breakpoint-down(lg) {
        padding-bottom: rem(50px);
      }
      h3 {
        font-size: rem(22px);
        line-height: rem(32px);
        letter-spacing: rem(.2px);
        margin: rem(0 0 20px);
        height: rem(64px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &-text {
      font-size: rem(16px);
      line-height: rem(26px);
      color:cl(text);
      height: rem(104px);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      transition: all.3s ease;
      p {
        margin: 0;
      }
    }
    &-more {
      position: absolute;
      bottom: rem(18px);
      left:rem(28px);
      width:calc(100% - 3.5rem);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: rem(14px);
      line-height: rem(16px);
      font-family: $fontTitle;
      transition: all.3s linear;
      color:white;
      @include media-breakpoint-up(xl) {
        transform: scale(0);
        bottom: rem(35px);
      }
      a {
        position: relative;
        padding: rem(0 15px);
        color:white;
        &:hover {
          text-decoration: none;
          color:cl(primary);
        }
        &:before,
        &:after {
          content: '';
          @extend .pos-centerY;
          background: cl(primary);
          height: 1px;
          width:rem(1000px);
        }
        &:before {
          right:100%;
        }
        &:after {
          left:100%;
        }
      }
    }
  }
}