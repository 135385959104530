.lang-inner {
  @include media-breakpoint-down(xs) {
    width:100%;
    position: absolute;
    z-index: 3;
    right: -100%;
    top:6rem;
    transition: all 0.3s linear;
    text-align: center;
    &.show {
      right:0;
    }
  }
  .goog-te-gadget-simple {
    background: none;
    border: 0;

    .goog-te-gadget-icon {
      display: none;
    }

    .goog-te-menu-value {
      color: #fff;
    }

    .goog-te-menu-value {
      img {
        display: none;
      }

      span {
        color: #fff !important;
        font-family: $font;
        border: 0 !important;

        &:first-child {
          font-size: rem(16px);
        }

        &:last-child {
          font-size: rem(12px);
        }
      }
    }
  }

}
