.modal {
  &-backdrop {
    //background: cl(back);
    &.show {
      opacity: .9;
    }
  }
  &--form {
    .modal-dialog {
      max-width: rem(785px);
    }
  }
  &--schedule {
    .modal-dialog {
      max-width: 100%;
    }
    iframe {
      display: block;
      width: 100%;
      height: 650px;
      @include media-breakpoint-down(md) {
        height: 920px;
      }
    }
    .modal-inner {
      .close {
        top:0;
        right:rem(5px);
      }
    }
  }
  &-header {
    padding: rem(30px 0 10px);
    border:0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: rem(20px);
    color:cl(text);
    h5 {
      font-family: $fontTitle;
      font-size: rem(30px);
      font-weight: normal;
      margin: rem(0 0 10px);
      color:white;
      letter-spacing: rem(2px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        //font-size: rem(34px);
      }
    }
  }
  &-inner {
    width:100%;
    pointer-events: all;
    padding-top: 2rem;
  }
  &-content {
    border-radius: 0;
    position: relative;
    padding: rem(15px 60px);
    background: cl(back);
    border:1px solid cl(border);
    box-shadow:rem(0 0 35px cl(black, .5));
    @include media-breakpoint-down(xs) {
      padding: 1rem;
    }
  }
  .form {
    &-group {
      margin: rem(0 0 30px);
    }
    &-control,
    .custom-select {
      height: rem(40px);
      padding:rem(0 16px);
      font-size:rem(16px);
    }
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
  }
}